/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-unused-expressions */
interface PageEventProps {
  event: string;
  page: string;
}
interface CustomPageEvent {
  action: string;
  category: string;
  label: string;
  value: string;
}

export const PageViewGTM = (url: string) => {
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};

export const SendEventGTM = (action: string, category: string, label: string, value: string) => {
  const pageEvent: CustomPageEvent = {
    category,
    action,
    label,
    value,
  };
  window && window.dataLayer && window.dataLayer.push({ event: 'event', pageEvent });
  return pageEvent;
};

export const SendEventGTMWithSingleString = (value: string) => {
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: value,
    });
};
