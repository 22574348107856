import useAmpComponent from '@/hooks/useAmpComponent';
import { sponsorCategoryData, categorySponsorDataItemType, sponsoredCategoryType } from '@/lib/sponsors';
import classNames from '@/utils/className';

type sponsoredCategoryHeaderProps = {
  sponsoredCategory: sponsoredCategoryType;
  logoSize?: 'small' | 'large' | 'responsive';
};

export const SponseredCategoryHeader = ({ sponsoredCategory, logoSize = undefined }: sponsoredCategoryHeaderProps) => {
  const { ImageComponent } = useAmpComponent();
  const sponsorData: categorySponsorDataItemType[] = sponsorCategoryData[sponsoredCategory];

  if (!sponsorData) return null;

  return (
    <div className="mb-4 flex flex-col items-end justify-center">
      <span className="mb-1 text-[10px] font-light text-mglGray500">Conteúdo apoiado por</span>
      <div className="flex flex-row gap-2">
        {sponsorData.map((sponsor, index) => (
          <a key={`${index}`} className={classNames('flex flex-row items-center')} href={sponsor.url} rel="sponsored">
            <img
              title={sponsor.title}
              src={sponsor.image.desktop.src}
              height={sponsor.image.desktop.height}
              width={sponsor.image.desktop.width}
              alt={sponsor.title}
            />
          </a>
        ))}
      </div>
      <hr className="mt-4 w-full border-t border-mglGray100 border-opacity-20" />
    </div>
  );
};
